import './App.css';
import Main from "./components/Main"
import React from "react"

function App() {
  return (
    <Main />
  );
}

export default App;
